<template>
  <div class="container">
    <title-bar :title-stack="titleStack"/>
    <section class="column is-half is-offset-one-quarter">
      <b-message title="ERROR" type="is-danger" v-if="showError" v-model="showError">
        {{ messageError }}
      </b-message>
    </section>
    <section class="section is-main-section">
      <card-component class="has-table has-mobile-sort-spaced">
        <b-steps type="is-info" v-model="activeStep" :has-navigation="false" style="margin-top: 20px">
            <b-step-item label="Cargar Datos" icon="file-search-outline" :clickable="false">
              <h1 class="title has-text-centered">Cargar Datos</h1>
              <div class="card">
                <div class="content">
                  <section>
                    <b-field>
                      <b-upload v-model="dropFiles" drag-drop :required="true" @input="checkExcel()" name="file">
                        <section class="section">
                          <div class="content has-text-centered">
                            <p> <b-icon icon="upload" size="is-large"></b-icon> </p>
                            <p> Drop your files here or click to upload </p>
                          </div>
                        </section>
                      </b-upload>
                    </b-field>
                  </section>
                </div>
              </div>
            </b-step-item>

            <b-step-item label="Importar" icon="file-check-outline" :clickable="false">
              <h1 class="title has-text-centered">
                <b-icon icon="check" type="is-success" v-if="importacionOK"></b-icon>
                <b-icon icon="alert-circle" type="is-danger" v-else></b-icon>
                {{ titleImportar }}
              </h1>
              <b-table
                :data="datos"
                :columns="columnas"
                :paginated="true"
                per-page="30"
                striped
                :narrowed="true"
                :hoverable="true"
                :row-class="calculaClase"
              >
              <section class="section" slot="empty">
                <div class="content has-text-grey has-text-centered">
                  <template v-if="isLoading">
                    <p>
                      <b-icon icon="dots-horizontal" size="is-large"/>
                    </p>
                    <p>Fetching data...</p>
                  </template>
                  <template v-else>
                    <p>
                      <b-icon icon="emoticon-sad" size="is-large"/>
                    </p>
                    <p>No hay datos a mostrar</p>
                  </template>
                </div>
              </section>
            </b-table>
            <div class="buttons is-centered">
              <b-button class="button"
                type="is-info"
                icon-left="chevron-left"
                @click="backStep">Volver</b-button>
              <b-button class="button"
                type="is-info"
                :disabled="!importacionOK"
                icon-right="sticker-check-outline"
                @click="importarExcel">Importar</b-button>
            </div>
          </b-step-item>

          <template slot="navigation" slot-scope="{previous, next}" v-if="mostrarNavegacion">
            <b-button
              icon-left="chevron-left"
              :disabled="previous.disabled"
              @click.prevent="previous.action">
            </b-button>

            <b-button
              icon-right="chevron-right"
              :disabled="next.disabled"
              :action="accion"
              @click.prevent="next.action">
            </b-button>
          </template>
        </b-steps>
      </card-component>
    </section>
  </div>
</template>

<script>
import TitleBar from '@/components/template/TitleBar'
import CardComponent from '@/components/template/CardComponent'

export default {
  name: 'WImportRedexis',
  data () {
    return {
      dropFiles: null,
      activeStep: 0,
      showError: false,
      messageError: '',
      datos: [],
      importacionOK: false,
      titleImportar: 'Importando',
      mostrarNavegacion: false,
      isLoading: false,
      columnas: [{ field: 'mensaje', label: 'Mensaje' }]
    }
  },
  components: {
    TitleBar,
    CardComponent
  },
  computed: {
    titleStack () {
      return ['Importación Excel Redexis']
    }
  },
  methods: {
    checkExcel () {
      if (this.dropFiles.type !== 'application/vnd.ms-excel' && this.dropFiles.type !== 'application/xml' && this.dropFiles.type !== 'text/xml' && this.dropFiles.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        this.showError = true
        this.messageError = `El fichero ${this.dropFiles.name} no es un Excel`
        setTimeout(() => {
          this.showError = false
          this.messageError = ''
        }, 10000)
        this.dropFiles = null
      } else {
        this.showError = false
        this.messageError = ''
        this.validarExcel()
      }
    },
    async validarExcel () {
      try {
        this.nextStep()
        this.isLoading = true
        let formData = new FormData()
        formData.append('file', this.dropFiles)
        this.isLoading = true
        const { data } = await this.$api.importarExcelRedexis.post(formData)
        this.datos = data.mensajes
        this.importacionOK = data.resultado
        if (data.resultado) {
          this.titleImportar = 'Excel lista para importar'
        } else {
          this.titleImportar = 'Errores en la importación'
        }
      } catch (error) {
        this.$buefy.toast.open({
          message: error.message,
          type: 'is-danger'
        })
        this.backStep()
      } finally {
        this.isLoading = false
      }
    },
    calculaClase (row) {
      if (row.estado === 'ETI_UPDATE') {
        return 'is-warning'
      }
      if (row.estado === 'ETI_OK') {
        return 'is-success'
      }
      if (row.estado === 'ETI_ERROR') {
        return 'is-danger'
      }
      return ''
    },
    async importarExcel () {
      try {
        this.isLoading = true
        const params = {}
        params.accion = 'IMPORT_FIN'
        const { data } = await this.$api.proc.put('p_importacionRedexis', params)
        if (data.length > 0 && data[0].accion === 'OK') {
          this.$buefy.toast.open({
            message: 'Importada!',
            type: 'is-success'
          })
          this.dropFiles = null
          this.activeStep = 0
        } else {
          this.$buefy.toast.open({
            message: data[0].mensaje,
            type: 'is-danger'
          })
        }
      } catch (error) {
        this.$buefy.toast.open({
          message: error.message,
          type: 'is-danger'
        })
      } finally {
        this.isLoading = false
      }
    },
    nextStep () {
      this.activeStep += 1
    },
    backStep () {
      this.activeStep -= 1
      if (this.activeStep === 0) {
        this.dropFiles = null
      }
    }
  }
}
</script>
